import React, { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HomeIcon from '@mui/icons-material/Home';
import Modal from './Modal';
import { Alert, AlertTitle } from '@mui/material';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const MainFinalClient = () => {
    const { deliveryId } = useParams();
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const geocoderRef = useRef(null);
    const autocompleteServiceRef = useRef(null);
    const [deliveryPoint, setDeliveryPoint] = useState(null);
    const [suggestions, setSuggestions] = useState([]);    
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [driverLocation, setDriverLocation] = useState(null);
    const [deliveryMarker, setDeliveryMarker] = useState(null);
    const [driverMarker, setDriverMarker] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showInstructionsFinalModal, setShowInstructionsFinalModal] = useState(false);
    const currentMarkerRef = useRef(null);

    useEffect(() => {
        const loadGoogleMapsScript = () => {
          if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
              geocoderRef.current = new window.google.maps.Geocoder();
              autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
            };
            document.head.appendChild(script);
          } else {
            geocoderRef.current = new window.google.maps.Geocoder();
            autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
          }
        };
    
        loadGoogleMapsScript();
      }, []);

      useEffect(() => {
        let intervalId;
      
        // Check if deliveryPoint exists and has both latitude and longitude.
        if (deliveryPoint && deliveryPoint.latitude && deliveryPoint.longitude) {
          // With a valid location, hide the initial instructions and show final instructions.
          setShowInstructionsModal(false);
          setShowInstructionsFinalModal(true);
          // Refresh the final instructions modal every 45 seconds.
          intervalId = setInterval(() => {
            setShowInstructionsFinalModal(true);
          }, 45000);
        } else {
          // When no valid location is present, show the initial instructions.
          setShowInstructionsModal(true);
          setShowInstructionsFinalModal(false);
          // Refresh the initial instructions modal every 45 seconds.
          intervalId = setInterval(() => {
            setShowInstructionsModal(true);
          }, 45000);
        }
      
        return () => clearInterval(intervalId);
      }, [deliveryPoint]);

    useEffect(() => {
        // Fetch delivery data
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery/${deliveryId}`)
            .then(response => {
                setDeliveryPoint(response.data);
                console.log(response.data.driver_id)
                
            })
            .catch(error => console.error('Error fetching delivery data:', error));
    }, [deliveryId]);

    useEffect(() => {
      if (!deliveryPoint || !deliveryPoint.latitude || !deliveryPoint.longitude) {
        return;
      }
      
      const fetchDriverLocation = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
            params: {
              driver_id: deliveryPoint.driver_id, // Send the driver_id as a query parameter
            },
          });
          console.log('Driver location:', response.data);
  
          // Update the driver state with the new data
          setDriverLocation(response.data);
        } catch (error) {
          console.error('Error fetching driver location:', error);
        }
      };
  
      // Fetch the driver location every 4 seconds
      const intervalId = setInterval(fetchDriverLocation, 4000);
  
      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [deliveryPoint]);

    useEffect(() => {
        if (deliveryPoint && mapContainerRef.current && !mapRef.current) {
            // Default to Lima coordinates
            let mapCenter = [-77.03711992646711, -12.060235303763926];
            let zoomLevel = 12;
    
            // Check if deliveryPoint has valid latitude and longitude
            if (deliveryPoint.latitude && deliveryPoint.longitude) {
                mapCenter = [deliveryPoint.longitude, deliveryPoint.latitude];
                zoomLevel = 15; // Adjust zoom level based on preference
            }
    
            const mapInstance = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: mapCenter,
                zoom: zoomLevel
            });
    
            mapInstance.on('load', () => {
                console.log('Map is ready');
    
                // Add delivery point marker with custom icon if coordinates are available
                if (deliveryPoint.latitude && deliveryPoint.longitude) {
                    // Create a DOM element for the marker
                    const homeIconElement = document.createElement('div');
                    homeIconElement.innerHTML = ReactDOMServer.renderToString(<HomeIcon style={{ fontSize: '38px', color: 'black' }} />);
    
                    const deliveryMarkerInstance = new mapboxgl.Marker({
                        element: homeIconElement
                    })
                        .setLngLat([deliveryPoint.longitude, deliveryPoint.latitude])
                        .setPopup(new mapboxgl.Popup().setHTML(`<h3>${deliveryPoint.recipient_name}</h3>`))
                        .addTo(mapInstance);
    
                    setDeliveryMarker(deliveryMarkerInstance);
                }
            });
    
            mapInstance.on('click', (e) => {
                if (!deliveryPoint.latitude && !deliveryPoint.longitude) {
                  const location = { lat: e.lngLat.lat, lng: e.lngLat.lng };
    
                    // Add new marker
                    addMarker(location);
    
                    setSelectedLocation(location);
                }
            });
    
            mapRef.current = mapInstance;
        }
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [deliveryPoint]);

    useEffect(() => {
        if (!searchQuery.trim()) {
          setSuggestions([]);
          return;
        }
    
        if (!autocompleteServiceRef.current) return;
    
        autocompleteServiceRef.current.getPlacePredictions({
          input: searchQuery,
          componentRestrictions: { country: 'pe' },
          location: new window.google.maps.LatLng(-12.0464, -77.0428),
          radius: 50000,
        }, (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        });
      }, [searchQuery]);
    
      const handleSuggestionClick = (prediction) => {
        if (!geocoderRef.current) return;
        geocoderRef.current.geocode({ placeId: prediction.place_id }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const location = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            setSelectedLocation(location);
            addMarker(location);
            mapRef.current.flyTo({ center: [location.lng, location.lat], zoom: 17 });
          } else {
            console.error('No geometry found for this place.');
          }
        });
        setSearchQuery(prediction.description);
        setShowSuggestions(false);
        setSuggestions([]);
      };
    
    useEffect(() => {
        const R = 6371e3; // Earth's radius in meters
    
        const calculateDistance = (lat1, lon1, lat2, lon2) => {
            const toRadians = (degrees) => degrees * (Math.PI / 180);
            const dLat = toRadians(lat2 - lat1);
            const dLon = toRadians(lon2 - lon1);
    
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                      Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
            return R * c; // Distance in meters
        };
    
        if (driverLocation && mapRef.current && deliveryPoint) {
            // Remove previous driver marker if it exists
            if (driverMarker) {
                driverMarker.remove();
                console.log('Removed previous driver marker:', driverMarker);
            }
    
            // Create a DOM element for the driver marker
            const driverIconElement = document.createElement('div');
            driverIconElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Driver" style="width: 30px; height: 30px;" />`;
    
            // Add new driver marker with custom icon
            const newDriverMarker = new mapboxgl.Marker({
                element: driverIconElement
            })
                .setLngLat([driverLocation.longitude, driverLocation.latitude])
                .setPopup(new mapboxgl.Popup().setHTML(`<h3>${driverLocation.name}</h3>`))
                .addTo(mapRef.current);
            
            setDriverMarker(newDriverMarker);
            console.log('Added new driver marker:', newDriverMarker);
    
            // Check if the driver is within 10 meters of the delivery point
            const distance = calculateDistance(
                deliveryPoint.latitude,
                deliveryPoint.longitude,
                driverLocation.latitude,
                driverLocation.longitude
            );
    
            if (distance <= 10) {
                setShowModal(true);
                // Stop updates by clearing the interval if you have one, or remove the event listener
                return;
            }
    
            // Define the bounds to include both driver and delivery locations
            const bounds = new mapboxgl.LngLatBounds()
                .extend([driverLocation.longitude, driverLocation.latitude])
                .extend([deliveryPoint.longitude, deliveryPoint.latitude]);
    
            // Fit the map to the bounds with some padding
            mapRef.current.fitBounds(bounds, { padding: 50 });
        }
    }, [driverLocation, deliveryPoint]);

    const addMarker = ({ lat, lng }) => {
        if (currentMarkerRef.current) {
            currentMarkerRef.current.remove();
        }
    
        currentMarkerRef.current = new mapboxgl.Marker({ color: 'red' })
            .setLngLat([lng, lat])
            .addTo(mapRef.current);
    };
    

    const handleSave = async () => {
        if (selectedLocation) {
            const isConfirmed = window.confirm("¿Está seguro que la ubicación marcada es el punto exacto de entrega?");
            if (isConfirmed) {
                try {
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/delivery/${deliveryId}/location`, {
                        latitude: selectedLocation.lat,
                        longitude: selectedLocation.lng
                    });
                    alert('¡Ubicación guardada exitosamente!');
                    window.location.reload();
                } catch (error) {
                    console.error('Error saving location:', error);
                }
            }
        }
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const location = { lat: latitude, lng: longitude };
                    setSelectedLocation(location);
    
                    // Add new marker
                    addMarker(location);
    
                    mapRef.current.flyTo({
                        center: [longitude, latitude],
                        zoom: 16,
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleWhatsAppClickOffice = () => {
        window.open('https://wa.me/51977802104', '_blank');
    };
    const handleWhatsAppClickDriver = () => {
        if (driverLocation && driverLocation.phone_number) {
            // Format the phone number by adding the country code if not already present
            let phoneNumber = driverLocation.phone_number.startsWith('51') 
                ? driverLocation.phone_number 
                : `51${driverLocation.phone_number}`;
    
            window.open(`https://wa.me/${phoneNumber}`, '_blank');
        } else {
            console.error('Driver phone number is not available.');
        }
    };

    if (!deliveryPoint) {
        return <div>Loading...</div>;
    }

    return (
      <div style={{ height: "100vh", position: "relative" }}>
        {deliveryPoint && deliveryPoint.latitude && deliveryPoint.longitude && (
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              zIndex: 2000, // Ensure it stays on top
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/1.png`}
              alt="Logo"
              style={{ height: "80px", width: "140px" }}
            />
          </div>
        )}
        <div
          ref={mapContainerRef}
          style={{
            height:
              deliveryPoint.latitude && deliveryPoint.longitude
                ? "60vh"
                : "60vh",
          }}
        ></div>
        {!deliveryPoint.latitude && !deliveryPoint.longitude && (
          <>
            <div className="search-container" style={{ position: "absolute", top: "40px", left: "10px", zIndex: 1000, width: "70%" }}>
                <input
                    id="search-input"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setShowSuggestions(true);
                    }}
                    placeholder="Ingresa la dirección EXACTA de entrega"
                    className="search-input"
                    style={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "16px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                    }}
                />
                {showSuggestions && suggestions.length > 0 && (
                    <div style={{
                        background: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginTop: '2px',
                        maxHeight: '150px',
                        overflowY: 'auto',
                        position: 'relative',
                        zIndex: 2000,
                        color: 'black',
                    }}>
                        {suggestions.map(suggestion => (
                            <div
                                key={suggestion.place_id}
                                onClick={() => handleSuggestionClick(suggestion)}
                                style={{
                                    padding: '8px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #eee'
                                }}
                            >
                                {suggestion.description}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <IconButton
              style={{
                position: "absolute",
                bottom: "40%",
                right: 20,
                zIndex: 1000,
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "50%",
                color: "black",
                transform: "scale(1.25)",
                marginBottom: "10px", // Add some space between the icons
              }}
              onClick={handleWhatsAppClickOffice} // Define this function as needed
            >
              <WhatsAppIcon />
            </IconButton>

            <IconButton
              style={{
                position: "absolute",
                bottom: "88.5%",
                right: 20,
                zIndex: 1000,
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "50%",
                color: "black",
                transform: "scale(1.25)"
              }}
              onClick={handleGetCurrentLocation}
            >
              <MyLocationIcon />
            </IconButton>
          </>
        )}
        {deliveryPoint.latitude && deliveryPoint.longitude && (
          <>
            <div
              style={{
                height: "40vh",
                padding: "20px",
                backgroundColor: "#f7f7f7",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#000",
                }}
              >
                <h2>¡Hola {deliveryPoint.recipient_name}!</h2>
                <p>{deliveryPoint.user_name} tiene una entrega para ti. COD: {deliveryId}</p>
                {deliveryPoint.charge > 0 && (
                  <p>
                    El monto a pagar es de{" "}
                    <strong>S/.{deliveryPoint.charge}</strong>.
                  </p>
                )}
                {driverLocation && (
                  <Alert severity="info" style={{ marginTop: "20px" }}>
                    <AlertTitle>Información del Repartidor</AlertTitle>
                    <p>
                      Tu delivery será entregado por <strong>{driverLocation.name}</strong>
                      {deliveryPoint.arrival_time && (() => {
                        // Create a new Date object from arrival_time
                        const arrivalTime = new Date(deliveryPoint.arrival_time);
                        
                        // Add two hours to adjust the time
                        arrivalTime.setHours(arrivalTime.getHours() - 5);
                        
                        // Calculate the start and end of the delivery window
                        const startWindow = new Date(arrivalTime);
                        startWindow.setMinutes(startWindow.getMinutes() - 30); // 30 minutes before arrival time

                        const endWindow = new Date(arrivalTime);
                        endWindow.setMinutes(endWindow.getMinutes() + 30); // 30 minutes after arrival time


                        // Function to format time as 'HH:MM'
                        const formatTime = (date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        
                        return (
                          <>
                            {" "}entre <strong>{formatTime(startWindow)}</strong> y <strong>{formatTime(endWindow)}</strong>.
                          </>
                        );
                      })()}
                      . Verifica el código de entrega al recibir tu pedido.
                    </p>
                  </Alert>
                )}
              </div>
            </div>
            <IconButton
              style={{
                position: "absolute",
                bottom: "59%",
                right: 20,
                zIndex: 1000,
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "50%",
                color: "black",
                marginBottom: "10px",
                transform: "scale(0.7)",
              }}
              onClick={handleWhatsAppClickOffice}
            >
              <SupportAgentIcon />
            </IconButton>
            {driverLocation && (
            <IconButton
              style={{
                position: "absolute",
                bottom: "50%",
                right: 20,
                zIndex: 1000,
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "80%",
                color: "black",
                marginBottom: "10px",
                transform: "scale(1.4)",
              }}
              onClick={handleWhatsAppClickDriver}
            >
              <WhatsAppIcon />
            </IconButton>
            )}
          </>
        )}

        {!deliveryPoint.latitude && !deliveryPoint.longitude && (
          <div
            style={{
              height: "40vh",
              padding: "5px",
              backgroundColor: "#f7f7f7",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#000",
              }}
            >
              <div
                style={{
                  backgroundColor: '#fff3cd',  // soft light yellow background
                  color: '#856404',            // dark yellow text (warning color)
                  padding: '3px',
                  borderRadius: '3px'
                }}
              >
                Tu paquete de parte de {deliveryPoint.user_name} sera entregado en la ubicación que escogas en el mapa <img src={`${process.env.PUBLIC_URL}/locationIcon.png`} alt='Marcador' style={{ width: '20px', verticalAlign: 'middle' }} />.
              </div>
              <div
              style={{
                padding: '3px',
                
              }}
              >
              <button
                onClick={handleSave}
                style={{
                  display: "block",
                  width: "80%",
                  margin: "0 auto",
                  padding: "10px 20px",
                  backgroundColor: "#616569",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                Guardar ubicación de entrega
              </button>
              </div>
            </div>
          </div>
        )}
        {showInstructionsModal && (
        <Modal 
          message={
            <div style={{ padding: '10px', fontSize: '14px', lineHeight: '1.4' }}>
              <p>• Ingresa tu dirección en la barra de búsqueda.</p>
              <p>
                • O presiona el ícono{" "}
                <MyLocationIcon style={{ verticalAlign: 'middle' }} />{" "}
                para obtener tu ubicación actual (con tu permiso).
              </p>
              <p>• También desliza el mapa con tus dedos para ubicar la posición deseada.</p>
              <p>
                • Verifica que el marcador 
                (<img
                  src={`${process.env.PUBLIC_URL}/locationIcon.png`}
                  alt="Marcador"
                  style={{ width: '20px', verticalAlign: 'middle', margin: '0 3px' }}
                />) esté correctamente posicionado.
              </p>
              <p>• Haz clic en "Guardar ubicación de entrega" para confirmar.</p>
            </div>

          } 
          onClose={() => setShowInstructionsModal(false)}
        />
      )}
      {showInstructionsFinalModal && (
        <Modal 
          message={
            <div style={{ padding: "10px", fontSize: "14px", lineHeight: "1.4" }}>
              <p>• El delivery se realizará entre 1 y 8pm.</p>
              <p>• A partir de la 1pm conocerás el tiempo aproximado de entrega.</p>
              <p>
                • Repartidor:{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
                  alt="Driver"
                  style={{ width: "30px", height: "30px", verticalAlign: "middle" }}
                />
              </p>
              <p>
                • Haz clic aquí para hablar con el repartidor:{" "}
                <WhatsAppIcon style={{ verticalAlign: 'middle' }} />
              </p>
              <p>
                • Haz clic aquí para hablar con soporte FAST MAX:{" "}
                <SupportAgentIcon style={{ verticalAlign: 'middle' }} />
              </p>
              <p>• Mantén a la vista tu código: <strong>{deliveryPoint.id}</strong></p>
            </div>

          } 
          onClose={() => setShowInstructionsFinalModal(false)}
        />
      )}
        {showModal && (
            <Modal 
            message="¡Tu paquete ya llegó!" 
            onClose={() => setShowModal(false)} 
            />
        )}
      </div>
    );
    
    
      
};

export default MainFinalClient;