import React from 'react';
import { ButtonBase, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

function Options({ userId, username, onOptionClick, onBackClick, handleMapSelection, setActiveComponent, onLogout }) {
    const handleSupportClick = () => {
        window.open('https://wa.me/51977689676', '_blank');
    };

    const handleMasivoClick  = () => {
        setActiveComponent('Masivo');
      };

    return (
        <div>
            <ButtonBase onClick={() => { onBackClick(); onOptionClick('back'); }} style={{ padding: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </ButtonBase>
            <List>
                <ListItem button onClick={() => onOptionClick('my-account')} style={{ padding: '20px' }}>
                    <ListItemAvatar>
                        <Avatar></Avatar>
                    </ListItemAvatar>
                    {username && <ListItemText primary={username} secondary="Mi cuenta" style={{ color: 'black', fontWeight: 'bold' }} />}
                </ListItem>
            </List>
            <Divider variant="middle" />
            <List>
                <ListItem button onClick={() => onOptionClick('MyDeliveries')} style={{ padding: '20px' }}>
                    <AccessTimeIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Mis Entregas" style={{ color: 'black' }} />
                </ListItem>
                <ListItem button onClick={() => onOptionClick('Print')} style={{ padding: '20px' }}>
                    <PrintIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Rotulado" style={{ color: 'black' }}/>
                </ListItem>
                <ListItem button onClick={handleSupportClick} style={{ padding: '20px' }}>
                    <SupportAgentIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Soporte" style={{ color: 'black' }} />
                </ListItem>
                <ListItem button onClick={handleMapSelection} style={{ padding: '20px' }}>
                    <LocationOnIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Ubicacion" style={{ color: 'black' }}/>
                </ListItem>
                <ListItem button onClick={handleMasivoClick} style={{ padding: '20px' }}>
                    <DriveFolderUploadIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Masivo" style={{ color: 'black' }}/>
                </ListItem>
                <ListItem button onClick={onLogout} style={{ padding: '20px' }}>
                    <InfoIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Cerrar Sesion" style={{ color: 'black' }}/>
                </ListItem>
            </List>
        </div>
    );
}

export default Options;
